import React, { Component } from 'react';
import { Button, ButtonGroup, Container, Form, FormGroup, Table, Input, Label } from 'reactstrap';
import AppNavbar from '../AppNavbar';
import { Link } from 'react-router-dom';
import Fuse from 'fuse.js';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

class JobList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            jobs: [],
            isLoading: true,
            searchValue: '',
            accessToken: localStorage.getItem("accessToken"),
            inactiveJobs: false
        };
        this.remove = this.remove.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.getFile = this.getFile.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.getJobs = this.getJobs.bind(this);
        this.tokenExpired = this.tokenExpired.bind(this);
    }

    getJobs(inactiveJobs) {
        this.state.inactiveJobs = !!inactiveJobs;
        fetch("/job", {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.state.accessToken}`
            })
        })
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                }
                else if (response.status === 401) {
                    this.tokenExpired();
                }
            })
            .then((data) => {
                this.setState({
                    jobs: data ? data.filter(job => job.active !== this.state.inactiveJobs) : [],
                    isLoading: false
                });
            })
    }

    confirmDelete = (id, title) => {
        confirmAlert({
            title: 'Confirm to submit',
            message: `Are you sure to delete ${title}?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => this.remove(id)
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        })
    };

    tokenExpired = () => {
        confirmAlert({
            title: 'Token expired',
            message: `Please log in again to continue.`,
            buttons: [
                {
                    label: 'Login',
                    onClick: () => this.props.history.push('/')
                }
            ]
        })
    };

    async remove(id) {
        await fetch(`/job/${id}`, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.state.accessToken}`
            }
        }).then((response) => {
            if (response.status === 204) {
                this.setState({isLoading: true})
                this.getJobs()
            } else if (response.status === 401) {
                this.tokenExpired();
            }
        });
    }

    async getFile(jobId, fileName) {
        await fetch(`/job/file/${jobId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${this.state.accessToken}`
            }
        })
            .then(response => response.blob())
            .then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = fileName;
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove();  //afterwards we remove the element again
            });
    }

    handleChange(event) {
        this.setState({searchValue: event.target.value});
    }

    componentDidMount() {
        this.setState({isLoading: true});
        this.getJobs(this.state.inactiveJobs);
    }

    render() {
        const {jobs, isLoading} = this.state;

        if (isLoading) {
            return <p>Loading...</p>;
        }

        let options = {
            shouldSort: true,
            threshold: 0.6,
            location: 0,
            distance: 100,
            maxPatternLength: 32,
            minMatchCharLength: 1,
            keys: [
                "title"
            ]
        };

        let fuse = new Fuse(jobs, options);
        let result = this.state.searchValue ? fuse.search(this.state.searchValue) : jobs;
        let inactiveJobs = this.state.inactiveJobs
        let activeJobsButton = inactiveJobs ? 'Show Active' : 'Show Inactive';
        result = result ? result : [];

        return (
            <div>
                <AppNavbar/>
                <Container fluid>
                    <div className="float-right">
                        <Button color="success" tag={Link} to="/jobs/new">Add Job</Button>
                    </div>
                    <br/>
                    <br/>
                    <div className="float-right">
                        <ButtonGroup>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => this.getJobs(!inactiveJobs)}>
                                {activeJobsButton}
                            </Button>
                        </ButtonGroup>
                        <Form>
                            <FormGroup>
                                <Label for="search">Search</Label>
                                <Input type="text" name="search" id="search"
                                       onChange={this.handleChange} autoComplete="search"/>
                            </FormGroup>
                        </Form>
                    </div>
                    <h3>Jobs</h3>
                    <Table className="mt-4">
                        <thead>
                        <tr>
                            <th width="1fr">Title</th>
                            <th width="1fr">Active</th>
                            <th width="1fr">Notes</th>
                            <th width="1fr">Date Created</th>
                            <th width="1fr">Salary</th>
                            <th width="1fr">Postcode</th>
                            <th width="1fr">Job Description</th>
                            <th width="1fr">Manager</th>
                            <th width="1fr">Company</th>
                            <th width="1fr">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {result.map(job => {
                            let salary = job.minSalary && job.maxSalary ? '£' + job.minSalary + " - £" + job.maxSalary : null;
                            let fileButton = job.fileName
                                ? <Button size="sm" color="warning" onClick={() =>
                                    this.getFile(job.id, job.fileName)}>{job.fileName}</Button>
                                : null;

                            return <tr key={job.id}>
                                <td>{job.title}</td>
                                <td>{job.active ? 'Yes' : 'No'}</td>
                                <td>{job.notes.map((note) => note + ",\r")}</td>
                                <td>{job.dateCreated}</td>
                                <td>{salary}</td>
                                <td>{job.postcode}</td>
                                <td>{fileButton}</td>
                                <td>{job.manager ? job.manager.title : null}</td>
                                <td>{job.company ? job.company.title : null}</td>
                                <td>
                                    <ButtonGroup>
                                        <Button size="sm" color="primary" tag={Link} to={"/jobs/" + job.id}>Edit</Button>
                                        <Button size="sm" color="danger" onClick={() => this.confirmDelete(job.id, job.title)}>Delete</Button>
                                    </ButtonGroup>
                                </td>
                            </tr>
                        })}
                        </tbody>
                    </Table>
                </Container>
            </div>
        );
    }
}

export default JobList;