import React from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import PropTypes from 'prop-types';

class DropdownExample extends React.Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            dropdownOpen: false
        };
    }

    toggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    render() {
        return (
            <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                <DropdownToggle caret>
                    {this.props.title}
                </DropdownToggle>
                <DropdownMenu>
                    {this.populateMenuItems(this.props.items)}
                </DropdownMenu>
            </Dropdown>
        );
    }

    populateMenuItems = (items) => {
        let mappedItems = [];
        if (items != null) {
            items.map((item) => {
                mappedItems.push(<DropdownItem key={Math.random()}>{item.title}</DropdownItem>);
            });
        }
        return mappedItems;
    }
}

DropdownExample.props = {
    id: PropTypes.string,
    title: PropTypes.string,
    items: PropTypes.array,
    type: PropTypes.string,
};

export default DropdownExample;

