import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Button, Container, Form, FormGroup, Input, Label } from 'reactstrap';
import AppNavbar from '../AppNavbar';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'react-datepicker/dist/react-datepicker.css';
import Select from "react-select";
import salaries from "../common/salaries.json";
import { Creatable } from 'react-select';

class JobEdit extends Component {

    emptyItem = {
        title: '',
        active: true,
        dateCreated: moment(),
        minSalary: '',
        maxSalary: '',
        postcode: '',
        notes: [],
        file: null,
        company: null,
        manager: null
    };

    emptyFile = {
        fileId: "",
        fileName: ''
    };

    constructor(props) {
        super(props);
        this.state = {
            job: this.emptyItem,
            notes: [],
            selectedNotes: [],
            accessToken: localStorage.getItem("accessToken"),
            isChecked: 'on',
            file: '',
            jobFile: this.emptyFile,
            min: {
                value: 'Min',
                label: 'Min'
            },
            max: {
                value: 'Max',
                label: 'Max'
            },
            companies: [],
            selectedCompany: {},
            managers: [],
            selectedManager: {}
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleMinSalaryChange = this.handleMinSalaryChange.bind(this);
        this.handleMaxSalaryChange = this.handleMaxSalaryChange.bind(this);
        this.handleChangeFile = this.handleChangeFile.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.toggleChange = this.toggleChange.bind(this);
        this.handleCompanyChange = this.handleCompanyChange.bind(this);
        this.handleManagerChange = this.handleManagerChange.bind(this);
        this.handleJobNoteChange = this.handleJobNoteChange.bind(this);
    }

    async componentDidMount() {
        let jobData;
        if (this.props.match.params.id !== 'new') {
            await fetch(`/job/${this.props.match.params.id}`, {
                method: 'GET',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.state.accessToken}`
                })
            })
                .then((response) => {
                    if (response.status === 200) {
                        return response.json();
                    }
                    else {
                        this.props.history.push('/');
                    }
                })
                .then(data => {
                    jobData = data
                });
        }
        let companiesData = await this.getCompanies()
        let managersData = await this.getManagers()

        let jobCompany = jobData ? this.transformCompanies(jobData.company ? [jobData.company] : []) : null;
        let jobManager = jobData ? this.transformManagers(jobData.manager ? [jobData.manager] :  []) : null;
        let isChecked = jobData ? jobData.active ? 'on' : '' : this.state.job.active ? 'on' : '';
        let min = jobData ? jobData.minSalary : this.state.job.minSalary;
        let max = jobData ? jobData.maxSalary : this.state.job.maxSalary;
        let date = jobData ? jobData.dateCreated : this.state.job.dateCreated;

        this.setState({
            min: {
                value: min,
                label: min
            },
            max: {
                value: max,
                label: max
            },
            dateCreated: moment(date, 'DD/MM/YYYY'),
            isChecked: isChecked,
            selectedCompany: jobCompany,
            selectedManager: jobManager,
            job: jobData ? jobData : [],
            companies: companiesData,
            managers: managersData,
            selectedNotes: this.transformNotes(jobData ? jobData : []),
            isLoading: false
        });

        if (this.state.job.fileName) {
            await fetch(`/job/file/${this.props.match.params.id}`, {
                method: 'GET',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.state.accessToken}`
                })
            })
                .then((response) => {
                    if (response.status === 200) {
                        return response.json();
                    } else if (response.status === 401) {
                        this.tokenExpired();
                    }
                })
                .then((data) => {
                    if (data) {
                        this.state.jobFile = data.file
                    }
                })
        }
    }

    async getCompanies() {
        return await fetch('/company', {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.state.accessToken}`
            })
        })
            .then(response => response.json())
            .then(data => {
                return data
            });
    }

    async getManagers() {
        return await fetch('/manager', {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.state.accessToken}`
            })
        })
            .then(response => response.json())
            .then(data => {
                return data
            });
    }

    toggleChange() {
        let changedChecked = !this.state.isChecked;
        this.setState({isChecked: changedChecked});

        let job = {...this.state.job};
        job['active'] = !this.state.isChecked ? 'on' : '';
        this.setState({job: job});
    };

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let job = {...this.state.job};
        job[name] = value;
        this.setState({job});
    }

    handleDateChange(date) {
        this.setState({
            dateCreated: date
        });
        let job = {...this.state.job};
        job['dateCreated'] = date;
        this.setState({job});
    }

    handleMinSalaryChange(min) {
        this.setState({min});
        let job = {...this.state.job};
        job['minSalary'] = min.value.replace(",", "");
        this.setState({job});
    }

    handleMaxSalaryChange(max) {
        this.setState({max});
        let job = {...this.state.job};
        job['maxSalary'] = max.value.replace(",", "");
        this.setState({job});
    }

    handleChangeFile(event) {
        this.setState({file: event.target.files[0]});
    }

    handleManagerChange(selectedManager) {
        this.setState({selectedManager});
        let job = {...this.state.job};
        job['manager'] = selectedManager.managerEntry;
        this.setState({job});
    }

    handleCompanyChange(selectedCompany) {
        this.setState({selectedCompany});
        let job = {...this.state.job};
        job['company'] = selectedCompany.companyEntry;
        this.setState({job});
    }

    handleJobNoteChange(selectedNotes) {
        this.setState({selectedNotes});
        let job = {...this.state.job};

        let updatedJobNotes = [];
        selectedNotes.map((jobNote) => {
            updatedJobNotes.push(jobNote.value)
        });
        job['notes'] = updatedJobNotes;
        this.setState({job});
    }

    async handleSubmit(event) {
        event.preventDefault();
        const {job, file} = this.state;

        let resourceId
        job.dateCreated = this.state.dateCreated.format('DD/MM/YYYY')

        if (!job.notes) {
            job.notes = [];
        }
        job.company = job.company ? job.company.id : null;
        job.manager = job.manager ? job.manager.id : null;
        if (!job.active) {
            job.active = this.state.isChecked === 'on'
        }
        await fetch(job.id ? `/job/${this.props.match.params.id}` : '/job', {
            method: (job.id) ? 'PUT' : 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.state.accessToken}`
            },
            body: JSON.stringify(job),
        })
            .then((response) => {
                resourceId = response.headers.get("Resource-Id");
            })

        if (file && resourceId) {
            let formData = new FormData();
            formData.set('file', file);
            formData.set('fileName', file.name);

            await fetch(`/job/file/${resourceId}`, {
                method: "POST",
                headers: new Headers({
                    'Authorization': `Bearer ${this.state.accessToken}`
                }),
                body: formData,
            })
        }
        this.props.history.push('/jobs');
    }

    transformCompanies(companies) {
        let newCompanies = [];
        if (companies) {
            companies.forEach(company => {
                let entry = {};
                entry['value'] = company.title ? company.title : company.name;
                entry['label'] = company.title ? company.title : company.name;
                entry['companyEntry'] = company;

                newCompanies.push(entry);
            })
        }
        return newCompanies;
    }

    transformManagers(managers) {
        let newManagers = [];
        if (managers) {
            managers.forEach(manager => {
                let entry = {};
                entry['value'] = manager.title ? manager.title : manager.name;
                entry['label'] = manager.title ? manager.title : manager.name;
                entry['managerEntry'] = manager;

                newManagers.push(entry);
            })
        }
        return newManagers;
    }

    transformNotes(job) {
        let newJobsNotes = [];
        if (job && job.notes && Array.isArray(job.notes)) {
            job.notes.forEach(note => {
                let entry = {};

                entry['value'] = note;
                entry['label'] = note;

                newJobsNotes.push(entry);
            });
        }

        return newJobsNotes;
    }

    render() {
        const {job, companies, managers} = this.state;

        const title = <h2>{job.id ? 'Edit Jobs' : 'Add Jobs'}</h2>;
        let style = {
            'marginLeft' : '1rem'
        };
        return <div>
            <AppNavbar/>
            <Container>
                {title}
                <Form onSubmit={this.handleSubmit}>
                    <FormGroup>
                        <Label for="title">Title*</Label>
                        <Input autoFocus type="text" name="title" id="title" value={job.title || ''}
                               required={'required'} onChange={this.handleChange} autoComplete="title"/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="active">Active</Label>
                        <Input type="checkbox" checked={this.state.isChecked}
                               name="active" id="active"
                               style={style}
                               onChange={this.toggleChange} autoComplete="active"/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="dateCreated">Date Created</Label>
                        <DatePicker selected={this.state.dateCreated}
                                    onChange={this.handleDateChange}
                                    dateFormat={"DD/MM/YYYY"}
                                    utcOffset={0}
                                    dropdownMode={"select"}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="salary">Salary</Label>
                        <Select value={this.state.min}
                                onChange={this.handleMinSalaryChange}
                                options={salaries.salaries}
                                isMulti={false}
                                isSearchable={false}
                                closeMenuOnSelect={true}   />
                        <Select value={this.state.max}
                                onChange={this.handleMaxSalaryChange}
                                options={salaries.salaries}
                                isMulti={false}
                                isSearchable={false}
                                closeMenuOnSelect={true}   />
                    </FormGroup>
                    <FormGroup>
                        <Label for="postcode">Postcode</Label>
                        <Input type="text" name="postcode" id="postcode" value={job.postcode || ''}
                               onChange={this.handleChange} autoComplete="postcode"/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="notes">Job Notes</Label>
                        <Creatable
                            isMulti
                            value={this.state.selectedNotes}
                            onChange={this.handleJobNoteChange}
                            options={this.state.selectedNotes}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="file">Job Description</Label>
                        <Input type="file" onChange={this.handleChangeFile} name="file" id="file" accept=".doc, .docx, .pdf"/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="manager">Manager</Label>
                        <Select value={this.state.selectedManager}
                                onChange={this.handleManagerChange}
                                options={this.transformManagers(managers)}
                                isMulti={false}
                                isSearchable={true}
                                closeMenuOnSelect={true}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="company">Company</Label>
                        <Select value={this.state.selectedCompany}
                                onChange={this.handleCompanyChange}
                                options={this.transformCompanies(companies)}
                                isMulti={false}
                                isSearchable={true}
                                closeMenuOnSelect={true}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Button color="primary" type="submit">Save</Button>{' '}
                        <Button color="secondary" tag={Link} to="/jobs">Cancel</Button>
                    </FormGroup>
                </Form>
            </Container>
        </div>
    }
}

export default withRouter(JobEdit);