import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Button, Container, Form, FormGroup, Input, Label } from 'reactstrap';
import AppNavbar from '../AppNavbar';
import SelectDropdown from '../common/SelectDropdown';

class KeySkillEdit extends Component {

    emptyItem = {
        name: ''
    };

    constructor(props) {
        super(props);
        this.state = {
            keySkill: this.emptyItem,
            accessToken: localStorage.getItem("accessToken")
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async componentDidMount() {
        if (this.props.match.params.id !== 'new') {
            await fetch(`/key-skill/${this.props.match.params.id}`, {
                method: 'GET',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.state.accessToken}`
                })
            })
                .then((response) => {
                    if (response.status === 200) {
                        return response.json();
                    }
                    else {
                        this.props.history.push('/');
                    }
                })
                .then(data => this.setState({keySkill: data, isLoading: false}));
        }
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let keySkill = {...this.state.keySkill};
        keySkill[name] = value;
        this.setState({keySkill});
    }

    async handleSubmit(event) {
        event.preventDefault();
        const {keySkill} = this.state;

        await fetch(keySkill.id ? `/key-skill/${this.props.match.params.id}` : '/key-skill', {
            method: (keySkill.id) ? 'PUT' : 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.state.accessToken}`
            },
            body: JSON.stringify(keySkill),
        });
        this.props.history.push('/keySkills');
    }

    render() {
        const {keySkill} = this.state;
        const title = <h2>{keySkill.id ? 'Edit KeySkill' : 'Add KeySkill'}</h2>;
        return <div>
            <AppNavbar/>
            <Container>
                {title}
                <Form onSubmit={this.handleSubmit}>
                    <FormGroup>
                        <Label for="name">Name*</Label>
                        <Input autoFocus type="text" name="name" id="name" value={keySkill.name || ''}
                               required={'required'} onChange={this.handleChange} autoComplete="name"/>
                    </FormGroup>
                    <FormGroup>
                        <Button color="primary" type="submit">Save</Button>{' '}
                        <Button color="secondary" tag={Link} to="/keySkills">Cancel</Button>
                    </FormGroup>
                </Form>
            </Container>
        </div>
    }
}

export default withRouter(KeySkillEdit);