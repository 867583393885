import React, { Component } from 'react';
import { Button, ButtonGroup, Container, Table, Form, FormGroup, Input, Label } from 'reactstrap';
import AppNavbar from '../AppNavbar';
import { Link, withRouter } from 'react-router-dom';
import Fuse from 'fuse.js';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Dropdown from "../common/Dropdown";

class CompanyList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            companies: [],
            isLoading: true,
            searchValue: '',
            accessToken: localStorage.getItem("accessToken")
        };

        this.remove = this.remove.bind(this);
        this.getCompanies = this.getCompanies.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.tokenExpired = this.tokenExpired.bind(this);
    }


    componentDidMount() {
        this.setState({isLoading: true});

        this.getCompanies()
    }

    async getCompanies() {
        await fetch('/company', {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.state.accessToken}`
            })
        })
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                }
                else if (response.status === 401) {
                    this.tokenExpired();
                }
            })
            .then((data) => {
                this.setState({companies: data, isLoading: false});
            })
    }

    confirmDelete = (id, name) => {
        confirmAlert({
            title: 'Confirm to submit',
            message: `Are you sure to delete ${name}?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => this.remove(id)
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
                ]
        })
    };

    tokenExpired = () => {
        confirmAlert({
            title: 'Token expired',
            message: `Please log in again to continue.`,
            buttons: [
                {
                    label: 'Login',
                    onClick: () => this.props.history.push('/')
                }
            ]
        })
    };

    async remove(id) {
        await fetch(`/company/${id}`, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.state.accessToken}`
            }
        }).then((response) => {
            if (response.status === 204) {
                this.setState({isLoading: true})
                this.getCompanies()
            } else if (response.status === 401) {
                this.tokenExpired();
            }
        });
    }

    handleChange(event) {
        this.setState({searchValue: event.target.value});
    }

    render() {
        const {companies, isLoading} = this.state;

        if (isLoading) {
            return <p>Loading...</p>;
        }

        let options = {
            shouldSort: true,
            threshold: 0.6,
            location: 0,
            distance: 100,
            maxPatternLength: 32,
            minMatchCharLength: 1,
            keys: [
                "name"
            ]
        };

        let fuse = new Fuse(companies, options);
        let result = this.state.searchValue ? fuse.search(this.state.searchValue) : companies;
        result = result ? result : [];
        return (
            <div>
                <AppNavbar/>
                <Container fluid>
                    <div className="float-right">
                        <Button color="success" tag={Link} to="/companies/new">Add Company</Button>
                    </div>
                    <br/>
                    <br/>
                    <div className="float-right">
                        <Form>
                            <FormGroup>
                                <Label for="search">Search</Label>
                                <Input type="text" name="search" id="search"
                                       onChange={this.handleChange} autoComplete="search"/>
                            </FormGroup>
                        </Form>
                    </div>
                    <h3>Companies</h3>
                    <Table className="mt-4">
                        <thead>
                        <tr>
                            <th width="1fr">Name</th>
                            <th width="1fr">Notes</th>
                            <th width="1fr">Address1</th>
                            <th width="1fr">Address2</th>
                            <th width="1fr">City</th>
                            <th width="1fr">Postcode</th>
                            <th width="1fr">Phone Number</th>
                            <th width="1fr">Industry</th>
                            <th width="1fr">Jobs</th>
                            <th width="1fr">Managers</th>
                            <th width="1fr">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {result.map(company => {
                            return <tr key={company.id}>
                                <td style={{whiteSpace: 'nowrap'}}>{company.name}</td>
                                <td>{company.notes.map((note) => note + ",\r")}</td>
                                <td>{company.address.address1}</td>
                                <td>{company.address.address2}</td>
                                <td>{company.address.city}</td>
                                <td>{company.address.postcode}</td>
                                <td>{company.phoneNumber}</td>
                                <td>{company.industry}</td>
                                <td><Dropdown title={"Jobs"} items={company.jobs ? company.jobs : []} type={"jobs"}/></td>
                                <td><Dropdown title={"Managers"} items={company.managers ? company.managers : []} type={"managers"}/></td>
                                <td>
                                    <ButtonGroup>
                                        <Button size="sm" color="primary" tag={Link} to={"/companies/" + company.id}>Edit</Button>
                                    </ButtonGroup>
                                </td>
                            </tr>
                        })}
                        </tbody>
                    </Table>
                </Container>
            </div>
        );
    }
}

export default withRouter(CompanyList);