import React, { Component } from 'react';
import { Button, ButtonGroup, Container, Table, Form, FormGroup, Input, Label } from 'reactstrap';
import AppNavbar from '../AppNavbar';
import { Link } from 'react-router-dom';
import Fuse from 'fuse.js';
import Dropdown from "../common/Dropdown";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

class ManagerList extends Component {

    constructor(props) {
        super(props);
        this.state = {managers: [],
            isLoading: true,
            searchValue: '',
            accessToken: localStorage.getItem("accessToken")
        };
        this.remove = this.remove.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.tokenExpired = this.tokenExpired.bind(this);
    }

    componentDidMount() {
        this.setState({isLoading: true});
        this.getManagers()
    }
    getManagers() {
        fetch('/manager', {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.state.accessToken}`
            })
        })
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                }
                else if (response.status === 401) {
                    this.tokenExpired();
                }
            })
            .then((data) => {
                this.setState({managers: data, isLoading: false});
            })
    }

    confirmDelete = (id, name) => {
        confirmAlert({
            title: 'Confirm to submit',
            message: `Are you sure to delete ${name}?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => this.remove(id)
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        })
    };

    tokenExpired = () => {
        confirmAlert({
            title: 'Token expired',
            message: `Please log in again to continue.`,
            buttons: [
                {
                    label: 'Login',
                    onClick: () => this.props.history.push('/')
                }
            ]
        })
    };

    async remove(id) {
        await fetch(`/manager/${id}`, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.state.accessToken}`
            }
        }).then((response) => {

            if (response.status === 204) {
                this.setState({isLoading: true})
                this.getManagers()
            } else if (response.status === 401) {
                this.tokenExpired();
            }
        });
    }

    handleChange(event) {
        this.setState({searchValue: event.target.value});
    }

    render() {
        const {managers, isLoading} = this.state;

        if (isLoading) {
            return <p>Loading...</p>;
        }

        let options = {
            shouldSort: true,
            threshold: 0.6,
            location: 0,
            distance: 100,
            maxPatternLength: 32,
            minMatchCharLength: 1,
            keys: [
                "name"
            ]
        };

        let fuse = new Fuse(managers, options);
        let result = this.state.searchValue ? fuse.search(this.state.searchValue) : managers;
        result = result ? result : [];

        return (
            <div>
                <AppNavbar/>
                <Container fluid>
                    <div className="float-right">
                        <Button color="success" tag={Link} to="/managers/new">Add Manager</Button>
                    </div>
                    <br/>
                    <br/>
                    <div className="float-right">
                        <Form>
                            <FormGroup>
                                <Label for="search">Search</Label>
                                <Input type="text" name="search" id="search"
                                       onChange={this.handleChange} autoComplete="search"/>
                            </FormGroup>
                        </Form>
                    </div>
                    <h3>Managers</h3>
                    <Table className="mt-4">
                        <thead>
                        <tr>
                            <th width="1fr">Name</th>
                            <th width="1fr">Phone Number</th>
                            <th width="1fr">Email</th>
                            <th width="1fr">Job Title</th>
                            <th width="1fr">Jobs</th>
                            <th width="1fr">Company</th>
                            <th width="1fr">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                            {result.map(manager => {
                                return <tr key={manager.id}>
                                    <td>{manager.name}</td>
                                    <td>{manager.phoneNumber}</td>
                                    <td>{manager.email}</td>
                                    <td>{manager.jobTitle}</td>
                                    <td><Dropdown title={"Jobs"} items={manager.jobs ? manager.jobs : []} type={"jobs"}/></td>
                                    <td>{manager.company ? manager.company.title : null}</td>
                                    <td>
                                        <ButtonGroup>
                                            <Button size="sm" color="primary" tag={Link} to={"/managers/" + manager.id}>Edit</Button>
                                            <Button size="sm" color="danger" onClick={() => this.confirmDelete(manager.id, manager.name)}>Delete</Button>
                                        </ButtonGroup>
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </Table>
                </Container>
            </div>
        );
    }
}

export default ManagerList;