import  React from 'react';
import Select from 'react-select';

class SelectDropdown extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedOption: null,
        };
    }

    handleChange = (selectedOption) => {
        this.setState({ selectedOption });
        console.log(`Option selected:`, selectedOption);
    };
    render() {
        const { selectedOption } = this.state;

        return (
            <Select
                value={selectedOption}
                onChange={this.handleChange}
                options={this.props.items}
                isMulti={true}
                isSearchable={true}
                closeMenuOnSelect={false}
            />
        );
    }
}

export default SelectDropdown;