import React, { Component } from 'react';
import './App.css';
import styled from 'styled-components';
import { Button, Container, Form, FormGroup, Input, Label } from 'reactstrap';

const HomePageGrid = styled.div`
  background: #2980b9;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to bottom, #2980b9, #2c3e50);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, #2980b9, #2c3e50); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  display: grid;
  height: 100%;
  grid-template-rows: 1fr 1fr 1fr;
  grid-template-areas: "header"
                       "body";  
`;

const Header = styled.div`
  grid-area: header;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: ". title .";
  justify-items: center;
  align-items: center;
`;

const Title = styled.div`
  grid-area: title;
`;

const Body = styled.div`
  grid-area: body;
  display: grid;
  
  grid-template-areas: ". . ."
                       ". login ."
                       ". . ."; 
`;

const Login = styled.div`
  grid-area: login;
`;

class Home extends Component {

    emptyItem = {
        username: '',
        password: ''
    };

    constructor(props) {
        super(props);
        this.state = {
            user: this.emptyItem,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let user = {...this.state.user};
        user[name] = value;
        this.setState({user});
    }

    async handleSubmit(event) {
        event.preventDefault();

        await fetch('/user/login', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                this.state.user
            )
        })
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                }
                else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                localStorage.setItem("accessToken", data.accessToken);
                this.props.history.push('/candidates');
            })
            .catch((error) => {
                window.location.reload();
            })
    }

    render() {
        return (
            <HomePageGrid>
                <Header>
                    <Title>
                        <h2>Welcome to broadbench!</h2>
                    </Title>
                </Header>
                <Body>
                <Login>
                    <Container>
                        {'Please login below: '}

                        <Form onSubmit={this.handleSubmit}>
                            <FormGroup>
                                <Label for="username">Username*</Label>
                                <Input type="text" name="username" id="username"
                                       onChange={this.handleChange} autoComplete="username" required={'required'}/>
                            </FormGroup>
                            <FormGroup>
                                <Label for="password">Password*</Label>
                                <Input type="password" name="password" id="password"
                                       onChange={this.handleChange} autoComplete="password" required={'required'}/>
                            </FormGroup>
                            <FormGroup>
                                <Button color="primary" type="submit">Login</Button>{' '}
                            </FormGroup>
                        </Form>
                    </Container>
                </Login>
                </Body>
            </HomePageGrid>
        );
    }
}

export default Home;