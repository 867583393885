import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Button, Container, Form, FormGroup, Input, Label } from 'reactstrap';
import AppNavbar from '../AppNavbar';
import Select, {Creatable} from "react-select";
import {confirmAlert} from "react-confirm-alert";

class CandidateEdit extends Component {

    emptyItem = {
        id: '',
        name: '',
        address1: '',
        address2: '',
        city: '',
        postcode: '',
        email: '',
        phoneNumber: '',
        //cv: '',
        fileName: '',
        jobs: [],
        keySkills: [],
        managers: [],
        notes: []
    };

    emptyFile = {
        fileId: "",
        fileName: ''
    };

    constructor(props) {
        super(props);
        this.state = {
            candidate: this.emptyItem,
            jobs: [],
            selectedJob: [],
            keySkills: [],
            selectedKeySkill: [],
            managers: [],
            selectedManager: [],
            notes: [],
            selectedNotes: [],
            file: '',
            candidateFile: this.emptyFile,
            accessToken: localStorage.getItem("accessToken")
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleChangeFile = this.handleChangeFile.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleJobChange = this.handleJobChange.bind(this);
        this.handleKeySkillChange = this.handleKeySkillChange.bind(this);
        this.handleManagerChange = this.handleManagerChange.bind(this);
        this.handleNoteChange = this.handleNoteChange.bind(this);
        this.tokenExpired = this.tokenExpired.bind(this);
    }

    async componentDidMount() {
        await fetch('/job', {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.state.accessToken}`
            })
        })
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                }
                else {
                    return [];
                }
            })
            .then((data) => {
                this.setState({jobs: data})
            });

        await fetch('/key-skill', {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.state.accessToken}`
            })
        })
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                }
                else {
                    return [];
                }
            })
            .then((data) => {
                this.setState({keySkills: data})
            });

        await fetch('/manager', {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.state.accessToken}`
            })
        })
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                }
                else {
                    return [];
                }
            })
            .then((data) => {
                this.setState({managers: data})
            });

        if (this.props.match.params.id !== 'new') {
            await fetch(`/candidate/${this.props.match.params.id}`, {
                method: 'GET',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.state.accessToken}`
                })
            })
                .then((response) => {
                    if (response.status === 200) {
                        return response.json();
                    }
                    else if (response.status === 401) {
                        return this.tokenExpired();
                    }
                })
                .then((data) => data ? this.setState({
                    candidate: {
                        id: data.id,
                        name: data.name,
                        address1: data.address.address1,
                        address2: data.address.address2,
                        city: data.address.city,
                        postcode: data.address.postcode,
                        email: data.email,
                        phoneNumber: data.phoneNumber,
                        jobs: data.jobs,
                        keySkills: data.keySkills,
                        managers: data.managers,
                        notes: data.notes,
                        fileName: data.fileName
                    },
                    isLoading: false
                }) : [])

            if (this.state.candidate.fileName) {
                await fetch(`/candidate/file/${this.props.match.params.id}`, {
                    method: 'GET',
                    headers: new Headers({
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.state.accessToken}`
                    })
                })
                    .then((response) => {
                        if (response.status === 200) {
                            return response.json();
                        } else if (response.status === 401) {
                            this.tokenExpired();
                        }
                    })
                    .then((data) => {
                        if (data) {
                            this.state.candidateFile = data.file
                        }
                    })
            }
            this.setState({ isLoading: false })
        }

        let selectedJobs = this.state.selectedJob;
        let candidateJobs = this.transformJobs(this.state.candidate.jobs);
        candidateJobs.map((job) => {
            selectedJobs.push(job);
        });

        let selectedKeySkills = this.state.selectedKeySkill;
        let candidateKeySkills = this.transformKeySkills(this.state.candidate.keySkills);
        candidateKeySkills.map((keySkill) => {
            selectedKeySkills.push(keySkill);
        });

        let selectedManagers = this.state.selectedManager;
        let candidateManagers = this.transformManagers(this.state.candidate.managers);

        candidateManagers.map((manager) => {
            selectedManagers.push(manager);
        });
        this.setState({
            selectedJob: selectedJobs,
            selectedManager: selectedManagers,
            selectedKeySkill: selectedKeySkills
        });
    }

    tokenExpired = () => {
        confirmAlert({
            title: 'Token expired',
            message: `Please log in again to continue.`,
            buttons: [
                {
                    label: 'Login',
                    onClick: () => this.props.history.push('/')
                }
            ]
        })
    };

    handleJobChange(selectedJob) {
        this.setState({selectedJob});
        let candidate = {...this.state.candidate};
        let updatedJob = [];
        selectedJob.map((job) => {
            updatedJob.push(job.jobEntry);
        });
        candidate['jobs'] = updatedJob;
        this.setState({candidate});
    }

    handleKeySkillChange(selectedKeySkill) {
        this.setState({selectedKeySkill});
        let candidate = {...this.state.candidate};
        let updatedKeySkill = [];
        selectedKeySkill.map((keySkill) => {
            updatedKeySkill.push(keySkill.keySkillEntry)
        });
        candidate['keySkills'] = updatedKeySkill;
        this.setState({candidate});
    }

    handleManagerChange(selectedManager) {
        this.setState({selectedManager});
        let candidate = {...this.state.candidate};

        let updatedManager = [];
        selectedManager.map((manager) => {
            updatedManager.push(manager.managerEntry)
        });
        candidate['managers'] = updatedManager;
        this.setState({candidate});
    }

    handleNoteChange(selectedNotes) {
        this.setState({selectedNotes});
        let candidate = {...this.state.candidate};

        let updatedNotes = [];
        selectedNotes.map((note) => {
            updatedNotes.push(note.value)
        });
        candidate['notes'] = updatedNotes;
        this.setState({candidate});
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let candidate = {...this.state.candidate};
        candidate[name] = value;
        this.setState({candidate});
    }

    handleChangeFile(event) {
        this.setState({file: event.target.files[0]});
    }

    async handleSubmit(event) {
        event.preventDefault();
        const {candidate, file} = this.state;

        let candidateToSend = {
            name: candidate.name,
            address: {
                address1: candidate.address1,
                address2: candidate.address2,
                city: candidate.city,
                postcode: candidate.postcode
            },
            email: candidate.email,
            phoneNumber: candidate.phoneNumber,
            jobs: candidate.jobs.map((job) => job.id ? job.id : job),
            keySkills: candidate.keySkills.map((keySkill) => keySkill.id ? keySkill.id : keySkill),
            managers: candidate.managers.map((manager) => manager.id ? manager.id : manager),
            notes: candidate.notes
        }

        await this.saveCandidate(candidate, candidateToSend, file);
        this.props.history.push('/candidates');
    }

    async saveCandidate(candidate, candidateToSend, file) {
        let resourceId
       await fetch(candidate.id ? `/candidate/${candidate.id}` : '/candidate', {
            method: (candidate.id) ? 'PUT' : 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.state.accessToken}`
            }),
            body: JSON.stringify(candidateToSend),
        })
            .then((response) => {
                resourceId = response.headers.get("Resource-Id");
            })

        if (file && resourceId) {
            let formData = new FormData();
            formData.set('file', file);
            formData.set('fileName', file.name);

            await fetch(`/candidate/file/${resourceId}`, {
                method: "POST",
                headers: new Headers({
                    'Authorization': `Bearer ${this.state.accessToken}`
                }),
                body: formData,
            })
        }
    }
    transformNotes(candidateNotes) {
        let newCandidateNotes = [];
        if (candidateNotes && Array.isArray(candidateNotes)) {
            candidateNotes.forEach(note => {
                let entry = {};

                entry['value'] = note;
                entry['label'] = note;

                newCandidateNotes.push(entry);
            });
        }
        return newCandidateNotes;
    }

    transformJobs(jobs) {
        let newJobs = [];
        if (jobs && Array.isArray(jobs)) {
            jobs.forEach(job => {
                let entry = {};

                entry['value'] = job.title;
                entry['label'] = job.title;
                entry['jobEntry'] = job;

                newJobs.push(entry);
            });
        }
        return newJobs;
    }

    transformKeySkills(keySkills) {
        let newKeySkills = [];
        if (keySkills && Array.isArray(keySkills)) {
            keySkills.forEach(keySkill => {
                let entry = {};
                entry['value'] = keySkill.title ? keySkill.title : keySkill.name;
                entry['label'] = keySkill.title ? keySkill.title : keySkill.name;
                entry['keySkillEntry'] = keySkill;

                newKeySkills.push(entry);
            });
        }
        return newKeySkills;
    }

    transformManagers(managers) {
        let newManagers = [];
        if (managers && Array.isArray(managers)) {
            managers.forEach(manager => {
                let entry = {};
                entry['value'] = manager.title ? manager.title : manager.name;
                entry['label'] = manager.title ? manager.title : manager.name;
                entry['managerEntry'] = manager;

                newManagers.push(entry);
            });
        }
        return newManagers;
    }

    render() {
        const {candidate, jobs, keySkills, managers} = this.state;

        const title = <h2>{candidate.id ? 'Edit Candidate' : 'Add Candidate'}</h2>;

        return <div>
            <AppNavbar/>
            <Container>
                {title}
                <Form onSubmit={this.handleSubmit}>
                    <FormGroup>
                        <Label for="name">Name*</Label>
                        <Input autoFocus type="text" name="name" id="name" value={candidate.name || ''}
                               required={'required'} onChange={this.handleChange} autoComplete="name"/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="address1">Address1</Label>
                        <Input type="text" name="address1" id="address1" value={candidate.address1 || ''}
                               onChange={this.handleChange} autoComplete="address1"/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="address2">Address2</Label>
                        <Input type="text" name="address2" id="address2" value={candidate.address2 || ''}
                               onChange={this.handleChange} autoComplete="address2"/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="city">City</Label>
                        <Input type="text" name="city" id="city" value={candidate.city || ''}
                               onChange={this.handleChange} autoComplete="city"/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="postcode">Postcode</Label>
                        <Input type="text" name="postcode" id="postcode" value={candidate.postcode || ''}
                               onChange={this.handleChange} autoComplete="postcode"/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="email">Email</Label>
                        <Input type="text" name="email" id="email" value={candidate.email || ''}
                               onChange={this.handleChange} autoComplete="email"/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="phoneNumber">Phone Number</Label>
                        <Input type="text" name="phoneNumber" id="phoneNumber" value={candidate.phoneNumber || ''}
                               onChange={this.handleChange} autoComplete="phoneNumber"/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="notes">Notes</Label>
                        <Creatable
                            isMulti
                            value={this.state.selectedNotes}
                            onChange={this.handleNoteChange}
                            options={this.state.selectedNotes}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Label for="file">CV</Label>
                        <Input type="file" onChange={this.handleChangeFile} name="file" id="file" accept=".doc, .docx, .pdf"/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="jobs">Jobs</Label>
                        <Select value={this.state.selectedJob}
                                onChange={this.handleJobChange}
                                options={this.transformJobs(jobs)}
                                isMulti={true}
                                isSearchable={true}
                                closeMenuOnSelect={false}   />
                    </FormGroup>
                    <FormGroup>
                        <Label for="keySkills">Key Skills</Label>
                        <Select value={this.state.selectedKeySkill}
                                onChange={this.handleKeySkillChange}
                                options={this.transformKeySkills(keySkills)}
                                isMulti={true}
                                isSearchable={true}
                                closeMenuOnSelect={false}/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="managers">Managers</Label>
                        <Select value={this.state.selectedManager}
                                onChange={this.handleManagerChange}
                                options={this.transformManagers(managers)}
                                isMulti={true}
                                isSearchable={true}
                                closeMenuOnSelect={false}/>
                    </FormGroup>
                    <FormGroup>
                        <Button color="primary" type="submit">Save</Button>{' '}
                        <Button color="secondary" tag={Link} to="/candidates">Cancel</Button>
                    </FormGroup>
                </Form>
            </Container>
        </div>
    }
}

export default withRouter(CandidateEdit);
