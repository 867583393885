import React, { Component } from 'react';
import { Button, ButtonGroup, Container, Table, Form, FormGroup, Input, Label } from 'reactstrap';
import AppNavbar from '../AppNavbar';
import Dropdown from "../common/Dropdown";
import { Link, withRouter } from 'react-router-dom';
import Fuse from 'fuse.js';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

class CandidateList extends Component {

    constructor(props) {
        super(props);
        this.state = {
            candidates: [],
            isLoading: true,
            searchValue: '',
            accessToken: localStorage.getItem("accessToken")
    };
        this.remove = this.remove.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.tokenExpired = this.tokenExpired.bind(this);
    }

    componentDidMount() {
        this.setState({isLoading: true});
        this.getCandidates()
    }

    getCandidates() {
        fetch('/candidate', {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.state.accessToken}`
            })
        })
            .then((response) => {
                if (response.status === 200) {
                    return response.json();
                }
                else {
                    return this.tokenExpired();
                }
            })
            .then((data) => {
                this.setState({candidates: data, isLoading: false});
            })
    }

    confirmDelete = (id, name) => {
        confirmAlert({
            title: 'Confirm to submit',
            message: `Are you sure to delete ${name}?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => this.remove(id)
                },
                {
                    label: 'No',
                    onClick: () => {}
                }
            ]
        })
    };

    tokenExpired = () => {
        confirmAlert({
            title: 'Token expired',
            message: `Please log in again to continue.`,
            buttons: [
                {
                    label: 'Login',
                    onClick: () => this.props.history.push('/')
                }
            ]
        })
    };

    async remove(id) {
        await fetch(`/candidate/${id}`, {
            method: 'DELETE',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.state.accessToken}`
            },
        }).then((response) => {
            if (response.status === 204) {
                this.setState({isLoading: true})
                this.getCandidates()
            } else if (response.status === 401) {
                this.tokenExpired();
            }
        });
    }

    async getFile(candidateId, fileName) {
        await fetch(`/candidate/file/${candidateId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${this.state.accessToken}`
            }
        })
            .then(response => response.blob())
            .then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = fileName;
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                a.remove();  //afterwards we remove the element again
            });
    }

    handleChange(event) {
        this.setState({searchValue: event.target.value});
    }

    render() {
        const {candidates, isLoading} = this.state;

        if (isLoading) {
            return <p>Loading...</p>;
        }

        let options = {
            shouldSort: true,
            threshold: 0.6,
            location: 0,
            distance: 100,
            maxPatternLength: 32,
            minMatchCharLength: 1,
            keys: [
                "name"
            ]
        };

        let fuse = new Fuse(candidates, options);
        let result = this.state.searchValue ? fuse.search(this.state.searchValue) : candidates;
        result = result ? result : [];

        return (
            <div>
                <AppNavbar/>
                <Container fluid>
                    <div className="float-right">
                        <Button color="success" tag={Link} to="/candidates/new">Add Candidate</Button>
                    </div>
                    <br/>
                    <br/>
                    <div className="float-right">
                        <Form>
                            <FormGroup>
                                <Label for="search">Search</Label>
                                <Input type="text" name="search" id="search"
                                       onChange={this.handleChange} autoComplete="search"/>
                            </FormGroup>
                        </Form>
                    </div>
                    <h3>Candidates</h3>
                    <Table className="mt-4">
                        <thead>
                        <tr>
                            <th width="1fr">Name</th>
                            <th width="1fr">Notes</th>
                            <th width="1fr">Address1</th>
                            <th width="1fr">Address2</th>
                            <th width="1fr">City</th>
                            <th width="1fr">Postcode</th>
                            <th width="1fr">Email</th>
                            <th width="1fr">Contact Number</th>
                            <th width="1fr">CV</th>
                            <th width="1fr">Jobs</th>
                            <th width="1fr">Key Skills</th>
                            <th width="1fr">Managers</th>
                            <th width="1fr">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {result.map(candidate => {
                            let fileButton = candidate.fileName
                                ? <Button size="sm" color="warning" onClick={() =>
                                    this.getFile(candidate.id, candidate.fileName)}>{candidate.fileName}</Button>
                                : null;
                            return <tr key={candidate.id}>
                                <td>{candidate.name}</td>
                                <td>{candidate.notes.map((note) => note + ",\r")}</td>
                                <td>{candidate.address.address1}</td>
                                <td>{candidate.address.address2}</td>
                                <td>{candidate.address.city}</td>
                                <td>{candidate.address.postcode}</td>
                                <td>{candidate.email}</td>
                                <td>{candidate.phoneNumber}</td>
                                <td>{fileButton}</td>
                                <td><Dropdown title={"Jobs"} items={candidate.jobs} type={"jobs"}/></td>
                                <td><Dropdown title={"Key Skills"} items={candidate.keySkills} type={"keyskills"}/></td>
                                <td><Dropdown title={"Managers"} items={candidate.managers} type={"managers"}/></td>
                                <td>
                                    <ButtonGroup>
                                        <Button size="sm" color="primary" tag={Link} to={"/candidates/" + candidate.id}>Edit</Button>
                                        <Button size="sm" color="danger" onClick={() => this.confirmDelete(candidate.id, candidate.name)}>Delete</Button>
                                    </ButtonGroup>
                                </td>
                            </tr>
                        })}
                        </tbody>
                    </Table>
                </Container>
            </div>
        );
    }
}

export default withRouter(CandidateList);
