import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Button, Container, Form, FormGroup, Input, Label } from 'reactstrap';
import AppNavbar from '../AppNavbar';
import Select from "react-select";

class UserEdit extends Component {

    emptyItem = {
        username: '',
        password: '',
        firstName: '',
        lastName: '',
        roles: [],
    };

    constructor(props) {
        super(props);
        this.state = {
            user: this.emptyItem,
            selectedRole: '',
            accessToken: localStorage.getItem("accessToken"),
            roles: [
                {
                    value: 'user',
                    label: 'Standard'
                },
                {
                    value: 'admin',
                    label: 'Admin'
                }]
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleRoleChange = this.handleRoleChange.bind(this);
    }

    async componentDidMount() {

        if (this.props.match.params.id !== 'new') {
            await fetch(`/user/${this.props.match.params.id}`, {
                method: 'GET',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.state.accessToken}`
                })
            })
                .then((response) => {
                    if (response.status === 200) {
                        return response.json();
                    }
                    else {
                        this.props.history.push('/');
                    }
                })
                .then(data => this.setState({user: data, isLoading: false}));

            this.setState({selectedRole:
                    this.state.roles.filter(role => this.state.user.role === role.value)});
        }
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let user = {...this.state.user};
        user[name] = value;
        this.setState({user});
    }

    handleRoleChange(selectedRole) {
        this.setState({selectedRole});
        let user = {...this.state.user};
        user['role'] = selectedRole.value;
        this.setState({user})
    }

    async handleSubmit(event) {
        event.preventDefault();
        const user = {
            "firstName": this.state.user.firstName,
            "lastName": this.state.user.lastName,
            "password": this.state.user.password,
            "role": this.state.user.role,
            "username": this.state.user.username
        };
        await fetch(this.state.user.id ? `/user/${this.props.match.params.id}` : '/user', {
            method: (this.state.user.id) ? 'PUT' : 'POST',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.state.accessToken}`
            }),
            body: JSON.stringify(user)
        });
        this.props.history.push('/users');
    }

    render() {
        const {user: user} = this.state;
        const title = <h2>{user.id ? 'Edit User' : 'Add User'}</h2>;
        return <div>
            <AppNavbar/>
            <Container>
                {title}
                <Form onSubmit={this.handleSubmit}>
                    <FormGroup>
                        <Label for="username">Username*</Label>
                        <Input autoFocus type="username" name="username" id="username" value={user.username || ''} required={'required'}
                               onChange={this.handleChange} autoComplete="username"/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="password">Password*</Label>
                        <Input type="password" name="password" id="password" value={user.password || ''} required={'required'}
                               onChange={this.handleChange} autoComplete="password"/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="firstName">First Name</Label>
                        <Input type="text" name="firstName" id="firstName" value={user.firstName || ''}
                               onChange={this.handleChange} autoComplete="firstName"/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="lastName">Last Name</Label>
                        <Input type="text" name="lastName" id="lastName" value={user.lastName || ''}
                               onChange={this.handleChange} autoComplete="lastName"/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="role">Role</Label>
                        <Select value={this.state.selectedRole}
                                onChange={this.handleRoleChange}
                                options={this.state.roles}
                                isMulti={false}
                                closeMenuOnSelect={true} />
                    </FormGroup>
                    <FormGroup>
                        <Button color="primary" type="submit">Save</Button>{' '}
                        <Button color="secondary" tag={Link} to="/users">Cancel</Button>
                    </FormGroup>
                </Form>
            </Container>
        </div>
    }
}

export default withRouter(UserEdit);