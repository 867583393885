import React, { Component } from 'react';
import { Collapse, Button, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';

export default class AppNavbar extends Component {
    constructor(props) {
        super(props);
        this.state = {isOpen: false};
        this.toggle = this.toggle.bind(this);
        this.logout = this.logout.bind(this);
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    logout() {
        localStorage.removeItem("accessToken");
    }

    render() {
        return <Navbar color="dark" dark expand="md">
            <NavbarBrand tag={Link} to="/candidates">Candidates</NavbarBrand>
            <NavbarBrand tag={Link} to="/companies">Companies</NavbarBrand>
            <NavbarBrand tag={Link} to="/jobs">Jobs</NavbarBrand>
            <NavbarBrand tag={Link} to="/managers">Managers</NavbarBrand>
            <NavbarBrand tag={Link} to="/keySkills">KeySkills</NavbarBrand>
            <NavbarBrand tag={Link} to="/users">Users</NavbarBrand>
            <Collapse isOpen={this.state.isOpen} navbar>

            </Collapse>
            <Button tag={Link} to="/" color="primary" onClick={this.logout}>Logout</Button>

        </Navbar>;
    }
}