import React, { Component } from 'react';
import './App.css';
import Home from './Home';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import CandidateList from './candidates/CandidateList';
import CandidateEdit from './candidates/CandidateEdit';
import CompanyList from "./companies/CompanyList";
import CompanyEdit from "./companies/CompanyEdit";
import JobList from "./jobs/JobList";
import JobEdit from "./jobs/JobEdit";
import ManagerList from "./managers/ManagerList";
import ManagerEdit from "./managers/ManagerEdit";
import KeySkillList from "./keySkills/KeySkillList";
import KeySkillEdit from "./keySkills/KeySkillEdit";
import UserList from './users/UserList';
import UserEdit from './users/UserEdit';
import { CookiesProvider } from 'react-cookie';
import {Redirect} from "react-router-dom";

class App extends Component {
    render() {
        return (
            <CookiesProvider>
                <Router>
                    <Switch>
                        <Route path='/' exact={true} component={Home}/>
                        <Route path='/candidates' exact={true} component={CandidateList}/>
                        <Route path='/candidates/:id' exact={true} component={CandidateEdit}/>
                        <Route path='/companies' exact={true} component={CompanyList}/>
                        <Route path='/companies/:id' exact={true} component={CompanyEdit}/>
                        <Route path='/jobs' exact={true} component={JobList}/>
                        <Route path='/jobs/:id' exact={true} component={JobEdit}/>
                        <Route path='/managers' exact={true} component={ManagerList}/>
                        <Route path='/managers/:id' exact={true} component={ManagerEdit}/>
                        <Route path='/keySkills/' exact={true} component={KeySkillList}/>
                        <Route path='/keySkills/:id' exact={true} component={KeySkillEdit}/>
                        <Route path='/users/' exact={true} component={UserList}/>
                        <Route path='/users/:id' exact={true} component={UserEdit}/>
                        <Redirect from="*" to="/" />
                    </Switch>
                </Router>
            </CookiesProvider>
        )
    }
}

export default App;