import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Button, Container, Form, FormGroup, Input, Label } from 'reactstrap';
import AppNavbar from '../AppNavbar';
import {Creatable} from "react-select";

class CompanyEdit extends Component {

    emptyItem = {
        id: '',
        name: '',
        address1: '',
        address2: '',
        city: '',
        postcode: '',
        phoneNumber: '',
        industry: '',
        notes: []
    };

    constructor(props) {
        super(props);
        this.state = {
            item: this.emptyItem,
            notes: [],
            selectedNotes: [],
            accessToken: localStorage.getItem("accessToken")
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCompanyNoteChange = this.handleCompanyNoteChange.bind(this);
    }

    async componentDidMount() {
        if (this.props.match.params.id !== 'new') {
            await fetch(`/company/${this.props.match.params.id}`, {
                method: 'GET',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.state.accessToken}`
                })
            })
                .then((response) => {
                    if (response.status === 200) {
                        return response.json();
                    }
                    else if (response.status === 401) {
                        return this.tokenExpired();
                    }
                })
                .then(data => this.setState({
                    item: {
                        id: data.id,
                        name: data.name,
                        address1: data.address.address1,
                        address2: data.address.address2,
                        city: data.address.city,
                        postcode: data.address.postcode,
                        phoneNumber: data.phoneNumber,
                        industry: data.industry,
                        notes: data.notes
                    }
                }));
        }

        this.setState({
            selectedNotes: this.transformNotes(this.state.item ? this.state.item : []),
            isLoading: false
        });
    }

    transformNotes(company) {
        let newCompanyNotes = [];
        if (company && company.notes && Array.isArray(company.notes)) {
            company.notes.forEach(note => {
                let entry = {};

                entry['value'] = note;
                entry['label'] = note;

                newCompanyNotes.push(entry);
            });
        }

        return newCompanyNotes;
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let item = {...this.state.item};
        item[name] = value;
        this.setState({item});
    }

    handleCompanyNoteChange(selectedNotes) {
        this.setState({selectedNotes});
        let item = {...this.state.item};

        let updatedCompanyNotes = [];
        selectedNotes.map((companyNote) => {
            updatedCompanyNotes.push(companyNote.value)
        });
        item['notes'] = updatedCompanyNotes;
        this.setState({item});
    }

    async handleSubmit(event) {
        event.preventDefault();
        this.setState({isLoading: true});

        const {item} = this.state;

        let companyToSend = {
            name: item.name,
            address: {
                address1: item.address1,
                address2: item.address2,
                city: item.city,
                postcode: item.postcode
            },
            phoneNumber: item.phoneNumber,
            industry: item.industry,
            notes: item.notes
        }

        await fetch(item.id ? `/company/${this.props.match.params.id}` : '/company', {
            method: (item.id) ? 'PUT' : 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.state.accessToken}`
            },
            body: JSON.stringify(companyToSend),
        })
            .then(
                this.setState({isLoading: false})
            )
        this.props.history.push('/companies');
    }

    render() {
        const {item} = this.state;
        const title = <h2>{item.id ? 'Edit Company' : 'Add Company'}</h2>;

        return <div>
            <AppNavbar/>
            <Container>
                {title}
                <Form onSubmit={this.handleSubmit}>
                    <FormGroup>
                        <Label for="name">Name*</Label>
                        <Input autoFocus type="text" name="name" id="name" value={item.name || ''}
                               required={'required'} onChange={this.handleChange} autoComplete="name"/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="address1">Address1</Label>
                        <Input type="text" name="address1" id="address1" value={item.address1 || ''}
                               onChange={this.handleChange} autoComplete="address1"/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="address2">Address2</Label>
                        <Input type="text" name="address2" id="address2" value={item.address2 || ''}
                               onChange={this.handleChange} autoComplete="address2"/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="city">City</Label>
                        <Input type="text" name="city" id="city" value={item.city || ''}
                               onChange={this.handleChange} autoComplete="city"/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="postcode">Postcode</Label>
                        <Input type="text" name="postcode" id="postcode" value={item.postcode || ''}
                               onChange={this.handleChange} autoComplete="postcode"/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="phoneNumber">Phone number</Label>
                        <Input type="text" name="phoneNumber" id="phoneNumber" value={item.phoneNumber || ''}
                               onChange={this.handleChange} autoComplete="phoneNumber"/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="industry">Industry</Label>
                        <Input type="text" name="industry" id="industry" value={item.industry || ''}
                               onChange={this.handleChange} autoComplete="industry"/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="notes">Company Notes</Label>
                        <Creatable
                            isMulti
                            value={this.state.selectedNotes}
                            onChange={this.handleCompanyNoteChange}
                            options={this.state.selectedNotes}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Button color="primary" type="submit">Save</Button>{' '}
                        <Button color="secondary" tag={Link} to="/companies">Cancel</Button>
                    </FormGroup>
                </Form>
            </Container>
        </div>
    }
}

export default withRouter(CompanyEdit);