import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Button, Container, Form, FormGroup, Input, Label } from 'reactstrap';
import AppNavbar from '../AppNavbar';
import Select from "react-select";

class ManagerEdit extends Component {

    emptyItem = {
        name: '',
        company: null,
        email: '',
        phoneNumber: '',
        jobTitle: ''
    };

    constructor(props) {
        super(props);
        this.state = {
            manager: this.emptyItem,
            accessToken: localStorage.getItem("accessToken"),
            companies: [],
            selectedCompany: {}
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCompanyChange = this.handleCompanyChange.bind(this);
    }

    async componentDidMount() {
        if (this.props.match.params.id !== 'new') {
            await fetch(`/manager/${this.props.match.params.id}`, {
                method: 'GET',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${this.state.accessToken}`
                })
            })
                .then((response) => {
                    if (response.status === 200) {
                        return response.json();
                    }
                    else {
                        this.props.history.push('/');
                    }
                })
                .then(data => this.setState({manager: data, isLoading: false}));
        }

        await fetch('/company', {
            method: 'GET',
            headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.state.accessToken}`
            })
        })
            .then(response => response.json())
            .then(data => this.setState({companies: data, isLoading: false}));

        let managerCompany = this.transformCompanies([this.state.manager.company]);
        this.setState({selectedCompany: managerCompany});
    }

    handleChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        let manager = {...this.state.manager};
        manager[name] = value;
        this.setState({manager});
    }

    handleCompanyChange(selectedCompany) {
        this.setState({selectedCompany});
        let manager = {...this.state.manager};
        manager['company'] = selectedCompany.companyEntry;
        this.setState({manager});
    }

    transformCompanies(companies) {
        let newCompanies = [];
        companies.map(company => {
            if (company) {
                let companyEntry = company.id;
                let entry = {};

                entry['value'] = company.id;
                entry['label'] = company.title ? company.title : company.name;
                entry['companyEntry'] = companyEntry;

                newCompanies.push(entry);
            }
        });
        return newCompanies;
    }

    async handleSubmit(event) {
        event.preventDefault();
        const {manager} = this.state;

        let managerToSend = {
            name: manager.name,
            company: manager.company,
            email: manager.email,
            phoneNumber: manager.phoneNumber,
            jobTitle: manager.jobTitle
        }

        await fetch(manager.id ? `/manager/${this.props.match.params.id}` : '/manager', {
            method: (manager.id) ? 'PUT' : 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${this.state.accessToken}`
            },
            body: JSON.stringify(managerToSend),
        });
        this.props.history.push('/managers');
    }

    render() {
        const {manager} = this.state;
        const title = <h2>{manager.id ? 'Edit Manager' : 'Add Manager'}</h2>;
        return <div>
            <AppNavbar/>
            <Container>
                {title}
                <Form onSubmit={this.handleSubmit}>
                    <FormGroup>
                        <Label for="name">Name*</Label>
                        <Input autoFocus type="text" name="name" id="name" value={manager.name || ''}
                               required={'required'} onChange={this.handleChange} autoComplete="name"/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="phoneNumber">Phone Number</Label>
                        <Input type="text" name="phoneNumber" id="phoneNumber" value={manager.phoneNumber || ''}
                               onChange={this.handleChange} autoComplete="phoneNumber"/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="email">Email</Label>
                        <Input type="text" name="email" id="email" value={manager.email || ''}
                               onChange={this.handleChange} autoComplete="email"/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="jobTitle">Job Title</Label>
                        <Input type="text" name="jobTitle" id="jobTitle" value={manager.jobTitle || ''}
                               onChange={this.handleChange} autoComplete="jobTitle"/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="company">Company</Label>
                        <Select value={this.state.selectedCompany}
                                onChange={this.handleCompanyChange}
                                options={this.transformCompanies(this.state.companies ? this.state.companies : [])}
                                isMulti={false}
                                isSearchable={true}
                                closeMenuOnSelect={true}
                        />
                    </FormGroup>
                    <FormGroup>
                        <Button color="primary" type="submit">Save</Button>{' '}
                        <Button color="secondary" tag={Link} to="/managers">Cancel</Button>
                    </FormGroup>
                </Form>
            </Container>
        </div>
    }
}

export default withRouter(ManagerEdit);